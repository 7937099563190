import React from "react";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Products from "../products";

import {
  TapaElegante,
  TapaPromocion,
  TapaAudaces,
  TapaClasica,
  TapaMinimalista,
  TapaDecorada,
  TapasVertedoras,
  TapasScrew,
  Titulo1,
  Titulo3,
  Titulo4,
} from "../../images/productos/tapas";
import "./tapas.scss";
import SEO from "../../components/SEO/Seo";

const Tapas = () => {
  // if (typeof window !== "undefined" && window.screen.width < 1000) {
  //   window.location = "http://www.blueskysa.com.ar/movile/30-tapas.htm";
  // }
  return (
    <>
      <SEO
        title="Bluesky SA - Tapas rosca, pilfer, aluminio, screw caps. Argentina. Tapas para botellas de vinos y licores. Tapas para botellas de aceites, aceite de oliva, vertedoras y dosificadoras. Falda corta y falda larga. Amplia variedad de colores. Argentina Mendoza"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  ,Blue Sky SA , Procesos adicionales , Satinado de botellas. Arenado , Pintado de botellas. Colores varios. Botellas coloreadas , Argentina."
        description=" Bluesky SA - Tapas rosca, pilfer, aluminio, screw caps. Argentina. Tapas para botellas de vinos y licores. Tapas para botellas de aceites, aceite de oliva, vertedoras y dosificadoras. Falda corta y falda larga. Amplia variedad de colores. Argentina Mendoza"
      />
      <Products subtitle="Tapas">
        <div className="tapas">
          <Row>
            <div>
              <img alt="Titulo" src={Titulo1} />
            </div>
          </Row>
          <Row>
            <div>
              <Image src={TapaAudaces} />
            </div>
            <div>
              <Image src={TapaElegante} />
            </div>
            <div>
              <Image src={TapaPromocion} />
            </div>
          </Row>
          <Row>
            <div>
              <img alt="Tapa decorada" src={TapaDecorada} />
            </div>
          </Row>
          <Row>
            <div>
              <Image src={TapaClasica} />
            </div>
            <div>
              <Image src={TapaAudaces} />
            </div>
            <div>
              <Image src={TapaMinimalista} />
            </div>
          </Row>
          <Row>
            <div className="mt-4">
              <img alt="Titulo tapa vertedora" src={Titulo3} />
            </div>
          </Row>
          <Row>
            <div>
              <img alt="Tapa Vertedora" src={TapasVertedoras} />
            </div>
          </Row>
          <Row>
            <div className="mt-4">
              <img alt="Titulo Screw" src={Titulo4} />
            </div>
          </Row>
          <Row>
            <div>
              <img alt="Tapas Screw" src={TapasScrew} />
            </div>
          </Row>
        </div>
      </Products>
    </>
  );
};

export default Tapas;
